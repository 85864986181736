import React from 'react';
import { Link, useStaticQuery, graphql  } from 'gatsby';

export default function Sidebar() {
  const { allMarkdownRemark: { edges: posts } } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: {order: ASC, fields: frontmatter___order}
        filter: {fields: {collection: {eq: "/docs/"}}}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            fields {
              slug
            }
            id
          }
        }
      }
    }
  `);

  return (
    <aside className="sidebar l-documentation__sidebar">
      <h2 className='sidebar__title'>Navigation</h2>
      <ul className="sidebar__navigation">
        {posts
        .map(post => (
          <li key={post.node.id}>
            <Link to={`${post.node.fields.slug}`}>{post.node.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
    </aside>
  );
}
