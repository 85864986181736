import React from 'react';
import { Link, graphql  } from 'gatsby';

// Import components
import Header from "../components/Header";
import Layout from '../components/Layout';
import SidebarDocs from '../components/SidebarDocs';
import SidebarBazar from '../components/SidebarBazar';
import Seo from '../components/SearchEngineOptimalization';
import TableOfContents from '../components/TableOfContents';

// Images
import ArrowLeft from '../images/icon/arrow-left.svg';
import ArrowRight from '../images/icon/arrow-right.svg';

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      frontmatter {
        github
        title
      }
      html
      headings {
        depth
        id
        value
      }
    }
  }
`;

export default function Post({ data: { markdownRemark: post }, pageContext }) {
  const {next, prev, labelText, labelType, slug} = pageContext;

  const { title, github } = post.frontmatter;
  const { html } = post;

  return (
    <Layout>
      <Seo title={title} />
      <Header type="dark" showLabel={true} labelText={labelText} labelType={labelType} />

      <main id="content" className="l-documentation">
        <div className="container">
          <div className="l-documentation__inner">
            {slug.includes("bazar") ? <SidebarBazar /> : <SidebarDocs />}
            <div className="l-documentation__body-helper">
              <article className="l-documentation__body">
                <h1 className="l-documentation__title">{title}</h1>
                {post.headings.length !== 0 &&
                <div className="l-documentation__table-of-content">
                  <section className="toc" aria-labelledby="toc-title">
                    <h3 className="toc__title" id="toc-title">On this page</h3>
                    <nav className="toc__navigation">
                      <TableOfContents headings={post.headings} />
                    </nav>
                  </section>
                </div>}
                <div className="l-documentation__content">
                  <div className="post-content" dangerouslySetInnerHTML={{ __html: html }}></div>
                  <div className="edit-on-github">
                    <a href={github}>Edit on GitHub</a>
                  </div>
                </div>
              </article>
              <div className="post-navigation">
                {prev &&
                  <Link className="post-navigation-item post-navigation-item--prev" to={`${prev.node.fields.slug}`}>
                    <span className="post-navigation-item__icon">
                      <ArrowLeft />
                    </span>
                    <span className="post-navigation-item__caption">
                      Previous
                      <span className="post-navigation-item__title">{prev.node.frontmatter.title}</span>
                    </span>
                  </Link>
                }
                {next &&
                  <Link className="post-navigation-item post-navigation-item--next" to={`${next.node.fields.slug}`}>
                    <span className="post-navigation-item__caption">
                      Next
                      <span className="post-navigation-item__title">{next.node.frontmatter.title}</span>
                    </span>
                    <span className="post-navigation-item__icon">
                      <ArrowRight />
                    </span>
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
